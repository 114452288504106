<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 v-html="text">
            </h3>
            <div class="single-file-check">
                <p style="margin-bottom: 0px;" @click="closeModal">확인</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'PopUpModal',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  emits: ['close-modal'],
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {
    closeModal () {
      this.$emit('close-modal');
    }
  }
};
</script>
