<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>결제가 완료된 스페이스입니다. <br/>해당 스페이스로 재생하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="linkMySpace()">아니오</p>
                <p id="color-text" @click="linkPlayer()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import router from "@/router";
import { leaveChannel } from "@/assets/js/webcast";

export default defineComponent({
  name: 'PaymentFinished2',
  props: {
    spaceId: String,
    spaceNm: String
  },
  methods: {
    async linkPlayer () {
      // channelId가 있을때
      if (this.$store.state.channelId !== '') await leaveChannel();
      if (this.$store.state.isBrandMusic) { // 브랜드 음원 값이 true인 경우
        this.$store.commit("setIsBrandMusicPlay", false); // 브랜드 음원의 재생 값 false
        this.$store.commit("setIsBrandMusic", false); // 브랜드 음원 값 false
        const video = document.getElementById("brand_video");
        video.pause();
      }
      VueCookies.set("spaceId", this.spaceId);
      this.$store.commit("setSpaceNm", this.spaceNm);
      this.$store.commit("setSpaceId", '');
      this.$store.commit("setMute", false);
      this.$emit('closePlay');
      router.push({ name: "Player" });
    },
    linkMySpace () {
      this.$emit('closePlay');
      router.push({ name: "MySpace" });
    }
  }
});
</script>
