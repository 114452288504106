<template>
    <div>
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <div class="space_pc">
            <div class="space5_popup modal">
                <div class="space5_popup_box modal_box" style="overflow: auto">
                    <div class="space5_title modal_title">
                        <h3>쿠폰등록</h3>
                        <div class="modal_close_btn" @click="$emit('closeSelect')">
                            <img src="/media/img/close_btn.png" alt="" />
                        </div>
                    </div>
                    <div class="space5_input">
                        <div>
                            <input
                                type="text"
                                placeholder="발급받은 쿠폰 코드를 입력해주세요."
                                v-model="couponCode"
                                @keyup.enter="insertCoupon()"
                                @keypress="checkCode = true"
                            />
                            <p style="margin-bottom: 0px" @click="insertCoupon()">등록</p>
                        </div>
                        <p style="margin-bottom: 0px" v-if="!checkCode">{{ message }}</p>
                    </div>
                    <div class="upload_cupon upload_cupon_scroll" v-if="couponList.length > 0">
                        <div
                            v-for="(item, index) in couponList"
                            :key="index"
                            :class="{ disabledCoupon: item.couponType !== 'All' && item.couponType !== spaceType }"
                        >
                            <span v-if="item.dynamicUseAt === 'N'">D-{{ endDay(item.endDate) }}</span>
                            <span v-if="item.dynamicUseAt === 'Y'">D-{{ endDay(item.dyEndDate) }}</span>
                            <h2>{{ item.couponNm }}</h2>
                            <p class="coupon_content">{{ item.couponContent }}</p>
                            <p v-if="item.dynamicUseAt === 'Y'">
                                {{ dateTime(item.dyStartDate) }} ~ {{ endDateTime(item.dyEndDate) }} 까지
                            </p>
                            <p v-if="item.dynamicUseAt === 'N'">
                                {{ dateTime(item.startDate) }} ~ {{ endDateTime(item.endDate) }} 까지
                            </p>
                            <div class="check_box">
                                <input
                                    type="radio"
                                    name="chk_info"
                                    :checked="coupon != null && coupon.couponNo === item.couponNo"
                                    :id="'check' + index"
                                    :disabled="item.couponType !== 'All' && item.couponType !== spaceType"
                                    @click="choiceCoupon(item, $event)"
                                /><label :for="'check' + index"></label>
                            </div>
                        </div>
                    </div>
                    <div class="upload_cupon upload_cupon_empty" v-else>
                        <img src="/media/img/Icon_Coupon.png" alt="" />
                        <p style="margin-bottom: 0px">등록된 쿠폰이 없습니다.</p>
                    </div>
                    <div v-if="unchosen">
                        <p style="color: red; margin: 0">쿠폰을 선택해주세요.</p>
                    </div>
                    <div class="btn_area">
                        <button id="prev_btn" @click="$emit('closeSelect')">취소</button>
                        <button @click="selectCoupon" v-if="couponList.length > 0">적용</button>
                        <button @click="selectCoupon" v-else disabled style="opacity: 0.7">적용</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="space_mo">
            <div class="space5_popup modal">
                <div class="space5_popup_box modal_box">
                    <div class="space5_title modal_title">
                        <div class="mo_space_header bg_color2">
                            <div class="mo_header_box">
                                <a href="#" @click="$emit('closeSelect')">
                                    <img src="/media/images/icon_back_24.svg" />
                                </a>
                                <!-- <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('closeSelect')">
                  <path d="M2.828 7.00072L7.778 11.9507L6.364 13.3647L0 7.00072L6.364 0.636719L7.778 2.05072L2.828 7.00072Z" fill="white"/>
                </svg> -->
                                <h2>쿠폰등록</h2>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="space5_input">
                        <div style="margin-top: 70px">
                            <input type="text" placeholder="쿠폰번호를 입력해주세요." v-model="couponCode" />
                            <p @click="insertCoupon()">등록</p>
                        </div>
                        <p style="color: red" v-if="!checkCode">{{ message }}</p>
                    </div>
                    <div class="upload_cupon upload_cupon_scroll" v-if="couponList.length > 0">
                        <div
                            v-for="(item, i) in couponList"
                            :key="i"
                            :class="{ disabledCoupon: item.couponType !== 'All' && item.couponType !== spaceType }"
                        >
                            <span v-if="item.dynamicUseAt === 'N'">D-{{ endDay(item.endDate) }}</span>
                            <span v-if="item.dynamicUseAt === 'Y'">D-{{ endDay(item.dyEndDate) }}</span>
                            <h2>{{ item.couponNm }}</h2>
                            <p class="coupon_content">{{ item.couponContent }}</p>
                            <p v-if="item.dynamicUseAt === 'Y'">
                                {{ dateTime(item.dyStartDate) }} ~ {{ endDateTime(item.dyEndDate) }} 까지
                            </p>
                            <p v-if="item.dynamicUseAt === 'N'">
                                {{ dateTime(item.startDate) }} ~ {{ endDateTime(item.endDate) }} 까지
                            </p>
                            <div class="check_box" @click="choiceCoupon(item, $event)">
                                <input
                                    type="radio"
                                    name="chk_infoM"
                                    :checked="coupon != null && coupon.couponId === item.couponId"
                                    :id="'checkM' + i"
                                    :disabled="item.couponType !== 'All' && item.couponType !== spaceType"
                                /><label :for="'checkM' + i"></label>
                            </div>
                        </div>
                    </div>
                    <div class="upload_cupon upload_cupon_empty" v-else>
                        <img src="/media/img/Icon_Coupon.png" alt="" />
                        <p style="margin-bottom: 0px">등록된 쿠폰이 없습니다.</p>
                    </div>
                    <div v-if="unchosen">
                        <p style="color: red; margin: 0">쿠폰을 선택해주세요.</p>
                    </div>
                    <div class="form_bottom modal_bottom_btn">
                        <button @click="selectCoupon">적용</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import Aside from '@/layout/aside/Aside.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import dayjs from 'dayjs';

export default defineComponent({
  namd: 'select',
  data () {
    return {
      couponCode: '',
      checkCode: true,
      couponList: [],
      couponInfo: {},
      today: dayjs().format('YY.MM.DD'),
      asideStatus: false,
      unchosen: false,
      message: '',
      isDisable: false
    };
  },
  props: {
    spaceType: String,
    coupon: Object
  },
  components: {
    Aside
  },
  methods: {
    endDay (value) {
      const today = dayjs().format('YYYY-MM-DD');
      const expireDate = dayjs(value);
      const endDay = expireDate.diff(today, 'day', true);
      return Math.floor(endDay);
    },
    async getMyCouponList () {
      const userId = VueCookies.get('userId');
      const spaceId = localStorage.getItem('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/product/getPayCouponList', { userId, spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.couponList = res.data.result.couponList;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    endDateTime (value) {
      // 쿠폰 만료일 -1일
      // return dayjs(value).subtract(1, 'day').format("YY.MM.DD");
      return dayjs(value).format('YY.MM.DD');
    },
    dateTime (value) {
      return dayjs(value).format('YY.MM.DD');
    },
    insertCoupon () {
      if (!this.isDisable) {
        // 얼럿 메세지 제거
        this.isDisable = true;
        this.checkCode = true;

        const userId = VueCookies.get('userId');
        const couponCode = this.couponCode;
        const aToken = VueCookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };

        axios
          .post('/api/product/insertCoupon', { userId, couponCode }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              const result = res.data.result;
              // console.log(result);
              if (result === '쿠폰 등록이 성공적으로 완료 되었습니다.') {
                this.getMyCouponList();
              } else {
                this.message = result;
                this.checkCode = false;
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.isDisable = false;
          });
      }
    },
    choiceCoupon (item, e) {
      if (this.couponInfo.couponId === item.couponId) {
        e.target != null ? (e.target.checked = false) : (e.path[0].checked = false);
        this.couponInfo = {
          discount: 0
        };
      } else {
        this.couponInfo = item;
      }
    },
    /* selectCoupon () {
      if (this.couponInfo.couponNm != null) {
        this.unchosen = false;
        this.$emit('selectCoupon', this.couponInfo);
        this.$emit('closeSelect');
      } else {
        this.unchosen = true;
      }
    }, */
    selectCoupon () {
      this.$emit('selectCoupon', this.couponInfo);
      this.$emit('closeSelect');
    },
    showAside () {
      this.asideStatus = true;
    }
  },
  mounted () {
    this.getMyCouponList();
    if (this.coupon != null) {
      this.couponInfo = this.coupon;
    }
  }
});
</script>

<style>
.upload_cupon.upload_cupon_scroll .disabledCoupon {
  pointer-events: none;
  cursor: default;
  background-color: rgba(225, 225, 225, 0.04);
}
.disabledCoupon span,
.disabledCoupon h2,
.disabledCoupon p {
  opacity: 0.34;
}
.modal {
  display: block;
}
.pagination-style {
  background: rgba(255, 255, 255, 0);
  margin: 0;
}
.payment_paging span.on {
  display: none;
  border-radius: none;
}
.upload_cupon_scroll {
  height: 490px;
  overflow: auto;
  display: block;
}
.upload_cupon .check_box input {
  margin-bottom: 0px;
}
@media all and (max-width: 500px) {
  .upload_cupon_scroll {
    height: 380px;
    overflow: auto;
    display: block;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
