<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 v-if="!registered">결제 카드 등록이 완료되었습니다.</h3>
            <h3 v-else>결제 카드 변경이 완료되었습니다.</h3>
            <div class="single-file-check">
                <p @click="checkInsertCard()">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import axios from "axios";

export default defineComponent({
  name: 'changedCard',
  props: {
    registered: Boolean
  },
  methods: {
    checkInsertCard () {
      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/product/updateCardPopupCheck", { userId }, { headers })
        .then(res => {
          console.log(res.data);
          if (res.data.resultCd === '0000') {
            // if (res.data.result > 0) {
            // this.$emit('openEventModal');
            // }
            this.$emit('getCardInfo');
            this.$emit('closeChanged');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>
